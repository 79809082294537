var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-modal',{ref:"popup",attrs:{"name":_vm.id,"variant":"subscription","hideCloseButton":true,"disableBodyScroll":true}},[_c('section',{staticClass:"px-8 pt-10"},[_c('div',{staticClass:"font-medium text-28px text-oCharcoal",domProps:{"innerHTML":_vm._s(
          _vm.$t(
            'components.reportManagement.modals.verificationPopupA.headline.title',
            {
              status:
                this.details.status === 'Verified'
                  ? _vm.$t(
                      'components.reportManagement.modals.verificationPopupA.headline.revert'
                    )
                  : _vm.$t(
                      'components.reportManagement.modals.verificationPopupA.headline.verify'
                    ),
              category: this.details.report_category,
            }
          )
        )}}),_c('section',{staticClass:"mt-6"},[_c('t-checkbox',{attrs:{"wrapped":"","label":_vm.$t(
              'components.reportManagement.modals.verificationPopupA.title.addRemarks'
            ),"name":"adminRemarksUi"},model:{value:(_vm.adminRemarksUi),callback:function ($$v) {_vm.adminRemarksUi=$$v},expression:"adminRemarksUi"}}),_c('div',{staticClass:"-mt-2 text-14px",staticStyle:{"margin-left":"28px"}},[_c('span',{staticClass:"text-gray-500"},[_vm._v(" "+_vm._s(_vm.$t( 'components.reportManagement.modals.verificationPopupA.subtext.addRemarks' ))+" ")])])],1),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.adminRemarksUi),expression:"adminRemarksUi"}],staticClass:"mt-6"},[_c('t-textarea',{model:{value:(_vm.adminRemarks),callback:function ($$v) {_vm.adminRemarks=$$v},expression:"adminRemarks"}})],1),_c('section',{staticClass:"mt-6"},[_c('t-checkbox',{attrs:{"wrapped":"","label":_vm.$t(
              'components.reportManagement.modals.verificationPopupA.title.deductPoint'
            ),"name":"deductPoint"},model:{value:(_vm.deductPoints),callback:function ($$v) {_vm.deductPoints=$$v},expression:"deductPoints"}}),_c('div',{staticClass:"-mt-2 text-14px",staticStyle:{"margin-left":"28px"}},[_c('p',{staticClass:"text-gray-500",domProps:{"innerHTML":_vm._s(
              _vm.$t(
                'components.reportManagement.modals.verificationPopupA.subtext.deductPoint',
                { deductAmount: _vm.getPointDeductionAmount }
              )
            )}})])],1),_c('section',{staticClass:"mt-12 ml-5"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('DisplayAvatar',{attrs:{"avatar":_vm.getReportedUserAvatar,"width":"4rem","height":"4rem"}}),_c('div',[_c('div',{staticClass:"font-medium text-14px text-oCharcoal"},[_vm._v(" "+_vm._s(_vm.getReportedUserFullName || '--')+" ")]),_c('div',{staticClass:"font-medium text-gray-500 text-14px"},[_vm._v(" "+_vm._s(_vm.getReportedUserId ? ("#" + (_vm.$truncate(_vm.getReportedUserId, -5))) : '--')+" ")])])],1),_c('div',{staticClass:"points",class:_vm.getPointDeductionClass},[_vm._v(" "+_vm._s(_vm.getPointDeductionAmount)+" ")])]),_c('div',{staticClass:"my-3 border-t"}),_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('DisplayAvatar',{attrs:{"avatar":_vm.getReporterUserAvatar,"width":"4rem","height":"4rem"}}),_c('div',[_c('div',{staticClass:"font-medium text-14px text-oCharcoal"},[_vm._v(" "+_vm._s(_vm.getReporterUserFullName || '--')+" ")]),_c('div',{staticClass:"font-medium text-gray-500 text-14px"},[_vm._v(" "+_vm._s(_vm.getReporterUserId ? ("#" + (_vm.$truncate(_vm.getReporterUserId, -5))) : '--')+" ")])])],1),_c('div',{staticClass:"points green"},[_vm._v(" "+_vm._s(_vm.getPointAdditionAmount)+" ")])])]),_c('div',{staticClass:"flex items-center justify-end px-4 py-4 mt-12 mb-4"},[_c('anchor-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$refs.popup.hide()}}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.cancel'))+" ")]),_c('anchor-button',{staticClass:"ml-4",attrs:{"variant":"primary"},on:{"click":_vm.executeAction}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.continue'))+" ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }